// Logo Navbar
var logoElement = document.getElementById('logo');
var logoWhite = logoElement.dataset.logowhite;
var logoDark = logoElement.dataset.logodark;
// Logo Sidenav
var logoElementNav = document.getElementById('logo-sidenav');
var logoWhiteNav = logoElementNav.dataset.logowhitenav;
var logoDarkNav = logoElementNav.dataset.logodarknav;
// Logo SideHeader
var logoElementSideHeader = document.getElementById('logo-sideheader');
var logoWhiteSideHeader = logoElementSideHeader.dataset.logowhitesideheader;
var logoDarkSideHeader = logoElementSideHeader.dataset.logodarksideheader;
// Logo BLM
var logoElementBlm = document.getElementById('logo-blm');
var logoWhiteBlm = logoElementBlm.dataset.logowhiteblm;
var logoDarkBlm = logoElementBlm.dataset.logodarkblm;
//dark theme switch
var themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
var themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');
var darkModeSwitch = document.getElementById('dark-mode-switch');

// Change the icons inside the button based on previous settings
if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    themeToggleLightIcon.classList.remove('hidden');
    document.documentElement.classList.add('dark');
    logoElement.src = logoWhite;
    logoElementNav.src = logoWhiteNav;
    logoElementBlm.src = logoWhiteBlm;
    logoElementSideHeader.src = logoWhiteSideHeader;
    darkModeSwitch.checked = true;
} else {
    themeToggleDarkIcon.classList.remove('hidden');
    document.documentElement.classList.remove('dark');
    logoElement.src = logoDark;
    logoElementNav.src = logoDarkNav;
    logoElementBlm.src = logoDarkBlm;
    logoElementSideHeader.src = logoDarkSideHeader;
    darkModeSwitch.checked = false;
}


function setDark() {
    document.documentElement.classList.add('dark');
    localStorage.setItem('color-theme', 'dark');
    logoElement.src = logoWhite;
    logoElementNav.src = logoWhiteNav;
    logoElementBlm.src = logoWhiteBlm;
    logoElementSideHeader.src = logoWhiteSideHeader;
    darkModeSwitch.checked = true;
}
function setLight() {
    document.documentElement.classList.remove('dark');
    localStorage.setItem('color-theme', 'light');
    logoElement.src = logoDark;
    logoElementNav.src = logoDarkNav;
    logoElementBlm.src = logoDarkBlm;
    logoElementSideHeader.src = logoDarkSideHeader;
    darkModeSwitch.checked = false;
}

var themeToggleBtn = document.getElementById('theme-toggle');
themeToggleBtn.addEventListener('click', function () {

    // toggle icons inside button
    themeToggleDarkIcon.classList.toggle('hidden');
    themeToggleLightIcon.classList.toggle('hidden');

    // if set via local storage previously
    if (localStorage.getItem('color-theme')) {
        if (localStorage.getItem('color-theme') === 'light') {
            setDark();
        } else {
            setLight();
        }

        // if NOT set via local storage previously
    } else {
        if (document.documentElement.classList.contains('dark')) {
            setLight();
        } else {
            setDark();
        }
    }
});

// Switch theme navbar
darkModeSwitch.addEventListener('change', () => {

    themeToggleDarkIcon.classList.toggle('hidden');
    themeToggleLightIcon.classList.toggle('hidden');

    if (darkModeSwitch.checked) {
       setDark();
    } else {
        setLight();
    }
})
